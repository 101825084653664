.accountform {
    display: flex;
    width: 100%;

    &-wrapper {

        margin-right: auto;

        &-reg {
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 20px 100px;
            gap: 10px;

            width: 386px;
            height: 68px;
            left: 426px;
            top: 726px;

            border: 1px solid #08003C;
            border-radius: 50px;
            background-color: transparent;
        }

        &-title {
            font-family: 'Nobile';
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 45px;
            margin-bottom: 30px;
        }

        &-image {
            width: 152px;
            height: 152px;
            background-image: url('../../assets//imgs/upload-image.png');
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 40px;
        }

        &-div {

            margin-bottom: 33px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            &-email {
                margin-bottom: 5px;
            }

            &-input {
                width: 386px;
                height: 62px;
                padding: 16px;
                border: 1px solid #9C99AD;
                border-radius: 10px;
            }

        }

        &-check {

            margin-bottom: 40px;

            &-checkbox {
                width: 18px;
                height: 18px;
                margin-right: 11px;
            }

            &-label {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
            }
        }

        &-button {
            display: flex;
            margin-bottom: 15px;

            &-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 20px 100px;
                gap: 10px;

                width: 386px;
                height: 68px;
                cursor: pointer;
                background: #3F66B0;
                border-radius: 50px;
                border: none;
                color: #fff;
                margin-right: 20px;
            }

            &-logout {
                box-sizing: border-box;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 20px 100px;
                gap: 10px;

                width: 386px;
                height: 68px;

                border: 1px solid #08003C;
                border-radius: 50px;
                background-color: transparent;
            }
        }
    }

    &-image {
        height: 400px;
        width: 400px;
        background-image: url(../../assets/imgs/form-image.png);
    }
    .column-wrap {
        display: flex;
        .right-col {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
    }
}

@media(max-width: 1366px) {
    .accountform .accountform-wrapper-div .accountform-wrapper-div-input {
        width: 30vw;
        height: 2.5rem;
    }
    .accountform .accountform-wrapper-button .accountform-wrapper-button-btn,
    .accountform .accountform-wrapper-button .accountform-wrapper-button-logout {
        width: 30vw;
        height: 3.5rem;
    }
}

@media(max-width: 980px) {
    .accountform .accountform-wrapper-title {
        font-size: calc(20px + 1vw);
    }
    .accountform .accountform-wrapper-div .accountform-wrapper-div-input {
        font-size: calc(14px + 1vw);
        width: 40vw;
        // min-width: 280px;
        height: 2rem;
    }
    .accountform .accountform-wrapper-div label {
        font-size: calc(14px + 1vw);
    }
    .accountform .accountform-wrapper-button .accountform-wrapper-button-btn,
    .accountform .accountform-wrapper-button .accountform-wrapper-button-logout {
        padding: 0;
        width: 40vw;
        min-width: 280px;
        height: 3rem;
    }
}

@media(max-width: 768px) {
    .accountform .column-wrap {
        margin-bottom: 40px;
    }
    .accountform .accountform-wrapper {
        width: 100%;
    }
    .accountform .accountform-wrapper form {
        display: flex;
        flex-direction: column;
    }
    .accountform .accountform-wrapper form .column-wrap {
        flex-direction: column;
    }
    .accountform .column-wrap .right-col {
        margin: 0;
        align-items: center;
        flex-direction: column-reverse;
    }
    .accountform .accountform-wrapper-div .accountform-wrapper-div-input,
    .accountform .accountform-wrapper-div  {
        width: 100%;
    }

}

@media(max-width: 768px) {
    .accountform-wrapper-button {
        flex-wrap: wrap;
    }
    .accountform .accountform-wrapper-button .accountform-wrapper-button-btn,
    .accountform .accountform-wrapper-button .accountform-wrapper-button-logout {
        width: 100%;
    }
    .accountform .accountform-wrapper-button .accountform-wrapper-button-btn {
        margin: 0;
    }
    .accountform .accountform-wrapper-button .accountform-wrapper-button-logout {
        margin-top: 15px;
    }
}