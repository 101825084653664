.food {
    background-image: url(../../assets/imgs/slider-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 600px;
    position: relative;
    padding: 0 120px;

    &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 74px;
        line-height: 1.1;
        margin-bottom: 40px;
        padding-top: 80px;
    }
}

@media(max-width: 1440px) {
    .food {
        padding: 0 3rem;
    }
}

@media(max-width: 980px) {
    .food {
        padding: 0 2rem;
    }
    .food .food-title {
        font-size: calc(28px + 2vw);
    }
}

@media(max-width: 560px) {
    .food {
        padding: 0 1rem;
    }
}