.form {
    display: flex;
    width: 100%;

    &-wrapper {

        margin-right: auto;

        &-reg {
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 20px 100px;
            gap: 10px;
            width: 386px;
            height: 68px;
            border: 1px solid #08003C;
            border-radius: 50px;
            background-color: transparent;
        }

        &-title {
            font-family: 'Nobile';
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 1.1;
            margin-bottom: 30px;
        }

        &-div {

            margin-bottom: 33px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            &-email {
                margin-bottom: 5px;
            }

            &-input {
                width: 386px;
                height: 62px;
                left: 426px;
                top: 351px;
                padding: 16px;
                border: 1px solid #9C99AD;
                border-radius: 10px;
            }

        }

        &-check {

            margin-bottom: 40px;

            &-checkbox {
                width: 18px;
                height: 18px;
                margin-right: 11px;
            }

            &-label {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
            }
        }

        &-button {

            margin-bottom: 15px;

            &-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 20px 100px;
                width: 386px;
                height: 68px;
                cursor: pointer;
                background: #3F66B0;
                border-radius: 50px;
                border: none;
                color: #fff;
            }
        }
    }

    &-image {
        height: 400px;
        width: 400px;
        background-image: url(../../assets/imgs/form-image.png);
        background-size: cover;
        background-repeat: no-repeat;
    }
}
@media(max-width: 1366px) {
    .form .form-wrapper form .form-wrapper-div .form-wrapper-div-input {
        width: 30vw;
        height: 2.5rem;
    }
    .form .form-wrapper .form-wrapper-button-btn {
        width: 30vw;
        height: 3.5rem;
    }
    .form .form-wrapper .form-wrapper-reg {
        width: 30vw;
        height: 3.5rem;
    }
}

@media(max-width: 980px) {
    .form {
        align-items: center;
    }
    .form .form-wrapper .form-wrapper-title {
        font-size: calc(20px + 1vw);
    }
    .form .form-wrapper form .form-wrapper-div .form-wrapper-div-email,
    .form .form-wrapper form .form-wrapper-div .form-wrapper-div-password{
        font-size: calc(14px + 1vw);
    }
    .form .form-wrapper form .form-wrapper-div .form-wrapper-div-input {
        font-size: calc(14px + 1vw);
        width: 40vw;
        min-width: 280px;
        height: 2rem;
    }
    .form .form-image {
        height: 300px;
        width: 300px;
    }

    .form .text-danger {
        font-size: calc(12px + 1vw);
    }
    .form .form-wrapper .form-wrapper-button-btn {
        padding: 0;
        width: 40vw;
        min-width: 280px;
        height: 3rem;
    }
    .form .form-wrapper .form-wrapper-reg {
        padding: 0;
        width: 40vw;
        min-width: 280px;
        height: 3rem;
    }
}
@media(max-width: 768px) {
    .form {
        flex-direction: column;
    }
    .form .form-wrapper form .form-wrapper-div .form-wrapper-div-input {
        width: 80vw;
    }
}

@media(max-width: 480px) {

}