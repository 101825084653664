.slider {
    // position: absolute;
    top: 150px;
    .slick-track {
        display: flex;
    }

    &-img {
        margin-bottom: 22px;
    }

    &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 41px;
    }
    .slick-slider {
        .slick-list {
            overflow: hidden;
            .slick-slide {
                text-align: center  ;
            }
        }
        .slick-arrow {
            font-size: 0;
            background: unset;
            border: none;
        }
        .slick-dots {
            width: fit-content;
            margin: 60px auto 0;
            text-align: center;
            li {
                display: inline;
            }
            li:not(:last-child) {
                margin-right: 10px;
            }

            li.slick-active {
                button {
                    opacity: 100%;
                }
            }
            button {
                font-size: 0;
                border: 2px solid #ACC9FF;
                background: #ACC9FF;
                width: 100px;
                border-radius: 5px;
                opacity: 50%;
                padding: 3px;
                cursor: pointer;
            }
        }
    }
}

@media(max-width: 1024px) {
    .slider .slider-img {
        width: 200px;
    }
    .slider .slider-title {
        font-size: calc(16px + 1vw);
    }
}
@media(max-width: 980px) {
    .slider .slick-slider .slick-list {
        overflow: unset;
    }
    .slider .slick-slider .slick-dots button {
        display: none;
    }
}

@media(max-width: 768px) {
    .slider .slick-slider .slick-list .slick-slide {
        text-align: unset;
    }
}
@media(max-width: 580px) {
    .slider .slider-img {
        width: 150px;
    }
}



@media (max-width: 400px) {
    .slider .slick-slider .slick-list {
        margin: 0 40px;
    }
}