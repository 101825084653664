.myOrders {

    display: flex;
    flex-direction: column;

    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 45px;
        color: #1D194B;
        margin-bottom: 40px;
    }
}