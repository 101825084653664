.header {
    display: flex;
    align-items: center;
    position: relative;

    &-mobile .header-nav-logo {
        display: none;
    }

    &-nav {
        height: 168px;
        text-align: center;
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        &-menu {
            display: flex;
            align-items: center;
            margin-right: 30px;

            a {
                margin-right: 10px;
                display: flex;
                img {
                    margin-left: 10px;
                }
            }

            .active {
                border-bottom: 3px solid #B0CBFF;
            }
        }

        &-logo {
            margin: 0 45px 0 15px;
        }

        a {
            text-decoration: none;

        }

        .active {
            border-bottom: 3px solid #B0CBFF;
        }

        &-item {
            margin-right: 30px;
        }

    }

    &-login {
        position: absolute;
        right: 2rem;
        display: flex;
        align-items: center;

        a {
            margin-right: 10px;
        }
    }
    #logo {
        border: none;
    }
    &-burger {
        display: none;
        position: absolute;
        width: 30px;
        height: 20px;
        z-index: 20;
        span {
            position: absolute;
            background-color: #030177;
            left: 0;
            width: 100%;
            height: 2px;
            top: 9px;
            transition: all 0.3s ease 0s;
        }
        &:before,
        &:after {
            content: '';
            background-color: #030177;
            position: absolute;
            width: 100%;
            height: 2px;
            transition: all 0.3s ease 0s;
        }
        &:before {
            top: 0;

        }
        &:after {
            bottom: 0;

        }
    }
    &-burger.active:before {
        background-color: #030177;
        transform:  rotate(45deg);
        top: 9px;
    }
    &-burger.active:after {
        background-color: #030177;
        transform:  rotate(-45deg);
        bottom: 9px;
    }
    &-burger.active {
        span {
            transform: scale(0);
        }
    }
}

@media(max-width: 980px) {
    .header-mobile .header-nav-logo {
        display: block;
        margin: 10px 15px 10px 25px;
        max-width: 75px;
    }
    .header {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        right: -120vw;
        background: #fff;
        z-index: 15;
        transition: .3s;
        display: none;
    }
    .header.active {
        display: flex;
        right: 0;
        transition: .3s;
    }
    .header-burger {
        display: block;
        top: 3vh;
        right: 3vw;
    }
    .header-nav {
        flex-direction: column;
        height: unset;
        flex: unset;
        align-items: flex-start;
        width: 60vw;
        margin: 0 auto;
    }
    // .header-login {
    //     display: none;
    // }
    .header #logo {
        display: none;
    }
    .header-nav .header-nav-item {
        font-size: calc(20px + .4vw);
    }
    .header-nav-item {
        margin: 0 0 1rem 0;
    }
    .header-nav-menu a {
        margin: 0;
        font-size: calc(20px + .4vw);
    }
    .header-nav-menu a img {
        display: none;
    }
}