.person {
    flex: 1 1 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 100px;

    //&:nth-child(2) {
    //    flex-direction: row-reverse;
    //}

    &-image {
        width: 388px;
        border-radius: 10px;
    }

    &-name,
    &-job,
    &-discr {
        text-align: center;
    }

    &-name {
        color: #1D194B;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    &-job {
        color: rgba(29, 25, 75, 0.6);
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        margin-bottom: 18px;
    }

    &-discr {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        max-width: 880px;
        color: #1D194B;
    }
}

@media(max-width: 1366px) {
    .person .person-image {
        width: 30vw;
        object-fit: cover;
        height: auto !important;
    }
}

@media(max-width: 840px) {
    .person {
        margin: 0 0 40px 0;
        display: flex;
        flex-direction: column;
    }
    .person .person-title,
    .person .person-job {
        margin-bottom: 5px;
    }
    .person:nth-child(2) {
        margin: 0 0 40px 0;
    }
    .person .person-image {
        width: unset;
        max-width: 80vw;
    }
    .person .person-discr {
        max-width: unset;
    }
}