.nav-mobile {
  margin-bottom: 40px;
  .menu {
    .menu-title {
      display: block;
      width: 100%;
      border: 1px solid #B0CBFF;
      border-radius: 12px;
      background: #FFFFFF;
      position: relative;
      padding: 10px 10px 10px 25px;
      color: #08003C;
      z-index: 10;
      &:hover {
        border: transparent;
      }
      &:after {
        content: "";
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg) translateY(50%);
        -webkit-transform: rotate(45deg) translateY(50%);
        position: absolute;
        right: 10%;
        top: 25%;
        z-index: 0;
      }
      span {

      }
    }

    a {
      color: #000;
      text-decoration: none;
      display: block;
    }

    li {

      ul {
        position: absolute;
        left: 0;
        top: 0;
        display: none;
        padding: 0;
        margin: 0;
        width: 100%;
        background: #fff;
        border: 1px solid #B0CBFF;
        border-radius: 12px;
        li {
          padding: 10px 10px 10px 25px;
          &:hover {
            background: #f3f1f1;
            border-radius: 12px;
          }
        }
      }
    }

    li:hover ul {
      display: block;
    }

  }
}
