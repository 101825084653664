.end-title {
    padding: 160px 120px 80px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

@media(max-width: 980px) {
    .end-title {
        padding: 4rem 1rem;
        font-size: calc(16px + 3vw);
    }
}

@media(max-width: 600px) {
    .end-title {
        padding: 2rem 1rem;
    }
}