.info {
    padding: 100px 0px 20px 120px;

    &-wrap {
        display: flex;
        flex-wrap: wrap;

        &-inform {
            margin-right: auto;
        }
    }
    div {

        &-wrapper {
            display: flex;

            &-discr {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
        
                color: #1D194B;
            }

            &-discr:not(:last-child) {
                margin-right: 70px;
            }
        }
    }


    &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 74px;
        line-height: 1.1;
        margin-bottom: 40px;
    }

    &-span {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        color: #1D194B;
        opacity: 0.7;
    }

    &-discr {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.2;
        color: #1D194B;
    }

    .info-wrapper {
        display: flex;

        &-discr:first-child {
            margin-right: 70px;
        }
    }

    &-map {
        width: 726px;
        height: 520px;
        background-image: url(../../assets/imgs/googlemap.png);
        background-repeat: no-repeat;
        margin-right: 0;
    }

    .info-wrap-inform-wrapper {
        margin-bottom: 40px;
    }
}

@media(max-width: 980px) {
    .info {
        padding: 5rem 2rem;
    }
    .info .info-title {
        font-size: calc(28px + 2vw);
    }
    .info .info-discr,
    .info .info-wrapper-discr  {
        font-size: calc(10px + 1.1vw);
    }
    .info .info-wrapper-discr:first-child {
        margin-right: 30px;
    }
}

@media(max-width: 560px) {
    .info {
        padding: 5rem 1rem;
    }
}