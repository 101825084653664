.banner {
    background-image: url('../../assets/imgs/contancts-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 52px;
        line-height: 1.1;
        color: #fff;
        text-align: center;
        padding: 100px;
    }
}

@media(max-width: 980px) {
    .banner .banner-title {
        font-size: calc(32px + 2vw);
    }
}