.content {
    margin-bottom: 80px;

    &-wrapper {

        &-title {
            font-weight: 500;
            font-size: 40px;
            line-height: 45px;
            color: #1D194B;
            margin-bottom: 30px;
        }

    }
}

@media(max-width: 1024px) {
    .content .content-wrapper-title {
        font-size: calc(20px + 1vw);
    }
}