.orders {
    position: relative;
    &-delete {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        &-icon {
            cursor: pointer;
            width: 4%;
        }
    }

    &-all { 

        padding: 10px 0 30px;
        border-bottom: 4px solid rgba(216, 232, 255, 0.38);

        &-item {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding-bottom: 10px;
        
        
            &-div {
                width: 37%;
                display: flex;
                &-image {
                    width: 200px;
                    height: 150px;
                    margin-right: 22px;
                    border-radius: 10px;
                }
        
                &-options {
                    &-title {
                        font-size: 26px;
                        font-weight: 500;
                        max-width: 300px;
                        margin-bottom: 7px;
                    }
        
                    &-item {
                        font-size: 22px;
                        font-weight: 500;
                        line-height: 30px;
                        color: #595667;
                    }
        
                    &-wrapper {
                        display: flex;
        
                        &-item {
                            font-size: 22px;
                            font-weight: 500;
                            margin-right: 5px;
                            line-height: 30px;
                            color: #595667;
                        }
                    }
                }
        
                &-div {
        
                    width: 16%;
                    margin-left: 54px;
                    margin-right: 65px;
                    &-price {
                        font-weight: 500;
                        font-size: 26px;
                        line-height: 28px;
                        color: #08003C;
                        margin-bottom: 4px;
                    }
        
                    &-weight {
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 26px;
                        color: rgba(8, 0, 60, 0.5);
        
                    }
                }
            }
        }
    }
    
}

@media(max-width: 1366px) {
    .orders .orders-all-item-div {
        width: 45%;
    }
}

@media(max-width: 980px) {
    .orders .btns .btns-remove,
    .orders .btns .btns-count,
    .orders .btns .btns-add, {
        font-size: calc(10px + 1vw);
    }
}

@media(max-width: 880px) {
    .orders .orders-all .btns {
        max-width: 40%;
        margin-top: 20px;
    }
    .orders .orders-all-item {
        flex-direction: column;
    }
    .orders .orders-all-item-div {
        width: 100%;
    }
    .orders .orders-all-item-div-btns {
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .orders .orders-all-item-div-div {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        margin-top: 20px;
    }
    .orders .orders-all-item-div-div-weight {
        margin-right: 20px;
    }
    .orders .orders-all-item-div-div {
        width: 100%;
    }

}

@media(max-width: 650px) {
    .orders .orders-delete-icon {
        width: 8%;
    }
}

@media(max-width: 580px) {
    .orders .btns .btns-remove,
    .orders .btns .btns-count,
    .orders .btns .btns-add, {
        height: 40px;
    }
    .orders .orders-all-item-div-image {
        width: 180px;
        height: auto;
    }
    .orders .orders-all-item-div-div {
        width: 100%;
        margin-left: 24px;
        margin-right: 15px;
    }
    .order .order-wrapper-title {
        font-size: calc(14px + 2vw);
    }
}

@media(max-width: 440px) {
    .orders .orders-all-item-div-image {
        width: 150px;
    }
}

@media(max-width: 380px) {
    .orders .orders-all-item-div-image {
        width: 130px;
    }
}