.nav {
    &-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 20px 144px 20px 40px;
        gap: 10px;

        width: 260px;
        height: 70px;
        margin-right: 45px;
        font-weight: 500;
    }

    &-title {
        font-family: 'Nobile';
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 38px;
        margin-bottom: 40px;

        color: #1D194B;
    }

    .active {
        background: #FFFFFF;
        border-left: 4px solid #B0CBFF;
        box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.08);
        border-radius: 0px 20px 20px 0px;
    }
    
}

@media(max-width: 1366px) {
    .nav .nav-item {
        padding: 1rem 3rem 1rem 2rem;
        min-width: 210px;
        width: 18vw;
    }
}

@media(max-width: 980px) {

}