.payment{
    width: 100%;
    text-align: center;
    h1{
        font-family: 'Nobile';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
        color: #1D194B;
    }
}