.banner {
    background-image: url('../../assets/imgs/homepage-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 820px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;


        &-div {
            height: 3px;
            width: 79px;
            background-color: #ACC9FF;
        }

        &-name {
            margin: 0px 12px;
            font-size: 24px;
            color: #fff;
        }

        &-title {
            font-size: 94px;
            margin-top: 20px;
            color: #fff;
            font-weight: 500;
            max-width: 800px;
            text-align: center;
        }

        &-discr {
            font-size: 26px;
            font-weight: 500;
            max-width: 560px;
            color: #fff;
            text-align: center;
        }
    }

}

@media(max-width: 980px){
    .banner .banner-wrapper-name {
        font-size: calc(12px + 1vw);
    }
    .banner .banner-wrapper-div {
        width: 30px;
    }
    .banner .banner-wrapper-title {
        font-size: calc(32px + 2vw);
        margin: 20px 0;
    }
    .banner .banner-wrapper-discr {
        font-size: calc(22px + 1vw);
    }
}

@media(max-width: 650px) {
    .banner {
        padding: 0 30px;
    }
}

@media(max-width: 360px) {
    .banner {
        padding: 0 20px;
    }
    .banner .banner-wrapper-div {
        width: 25px;
    }
}
