.banner-catering {
    background-image: url('../../assets/imgs/catering-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 72px;
        line-height: 81px;
        color: #fff;
    }
}

@media(max-width: 980px) {
    .banner-catering .banner-catering-title {
        font-size: calc(32px + 2vw);
    }
}