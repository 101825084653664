.btns {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 100%;

    &-remove {
        background-color: transparent;
        border: 1px solid #1D194B;
        border-radius: 20px 0px 0px 20px;
        padding: 0px 0px 0px 20px;
        border-right: 0px;
        font-size: 27px;
        cursor: pointer;
        float: left;
        width: 15%;
        height: 50px;
    }

    &-add {
        background-color: transparent;
        border: 1px solid #1D194B;
        border-radius: 0px 20px 20px 0px;
        padding: 0px 20px 0px 0px;
        border-left: 0px;
        cursor: pointer;
        font-size: 27px;
        float: left;
        width: 15%;
        height: 50px;
    }

    &-count {
        background-color: transparent;
        border: 1px solid #1D194B;
        border-radius: 0px;
        border-left: 1px;
        border-right: 1px;
        float: left;
        width: 70%;
        height: 50px;
    }
}

.btns:after {
    content: "";
    clear: both;
    display: table;
}