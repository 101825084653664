.profile {
    display: flex;
    padding: 3rem 5rem;
}

@media(max-width: 1366px) {
    .profile {
        padding: 3rem 3rem;
    }
}

@media(max-width: 980px) {
    .profile {
        flex-direction: column;
        padding: 3rem 2rem;
    }
}

@media(max-width: 480px) {
    .profile {
        padding: 2rem 1rem;
    }
}