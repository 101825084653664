.catering {
    padding: 157px 120px 0;
    position: relative;

    &-wrapper {
        max-width: 1200px;

        &-wrap {
            display: flex;
            align-items: center;
            margin-bottom: 31px;

            &-div {
                width: 209px;
                height: 3px;
                background-color: #ACC9FF;
                margin-left: 12px;
            }
        }

        &-title {
            font-size: 74px;
            font-weight: 600;
            margin-bottom: 12px;
        }

        &-discr {
            font-size: 26px;
            margin-bottom: 90px;
            line-height: 42px;
        }

        &-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 20px 70px;
            font-size: 26px;
            width: 386px;
            border: none;
            color: #fff;
            background: #3F66B0;
            border-radius: 50px;
        }
    
    }

    &-image {
        background-image: url(../../assets/imgs/about-image.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 710px;
        height: 560px;
        position: relative;
    }

    &-stats {
        width: 692px;
        height: 178px;
        border-left: 10px solid #B0CCFF;
        ;
        border-radius:  10px;
        background-color: #fff;
        box-shadow: 0px 2px 16px 14px rgba(176, 204, 255, 0.16);
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        z-index: 20;
        bottom: -40px;

        &-wrapper {
            h3 {
                font-size: 46px;
                font-weight: 500;
                color: #08003C;
            }

        }
    }

}

@media(max-width: 980px) {
    .catering {
        padding: 5rem 2rem;
    }
    .catering .catering-wrapper-title {
        font-size: calc(32px + 2vw);
    }
    .catering .catering-wrapper-discr {
        font-size: calc(12px + 2vw);
    }
}

@media(max-width: 560px) {
    .catering {
        padding: 5rem 1rem;
    }
    .catering .catering-wrapper-button {
        width: 90vw;
        max-width: 280px;
    }
}