.item {
    background: #FFFFFF;
    box-shadow: 0px 4px 26px rgba(29, 25, 75, 0.08);
    border-radius: 20px;
    margin-bottom: 30px;
    margin-right: 30px;

    &-card {
        width: 285px;
        position: relative;

        &-absolute {
            position: absolute;
            width: 285px;
            height: 420px;
            top: 0;
        }

        &-wrap {

            display: flex;
            flex-direction: column;
            position: relative;

            &-image {
                width: 285px;
                height: 213px;
                border-radius: 20px 20px 0px 0px;
            }

            &-svg {
                position: absolute;
                z-index: 1;
                width: 34px;
                height: 34px;
                top: 16px;
                right: 16px;
            }
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            padding: 14px 12px;
            justify-content: space-between;
            min-height: 200px;

            &-name {
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 26px;
                color: #08003C;
                max-width: 225px;
                min-height: 52px;
            }

            &-bottom {

                display: flex;
                flex-direction: column;
                height: 110px;
                justify-content: space-between;

                &-price {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 26px;
                    line-height: 28px;
                    margin-bottom: 5px;
                    color: #08003C;
                }

                &-weigth {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 26px;
                    color: rgba(8, 0, 60, 0.5);
                    margin-bottom: 14px;
                }

                &-button {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    gap: 8px;
                    z-index: 1;
                    width: 253px;
                    height: 50px;
                    background-color: transparent;
                    border: 1px solid #1D194B;
                    border-radius: 20px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

.no-photo { 
    height: 420px;
}

@media(max-width: 1480px) {
    .item {
        width: 100%;
        max-width: 280px;
    }
    .item .item-card {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 280px;
    }
    .item .item-card .item-card-wrapper {
        justify-content: flex-end;
    }
    .item .item-card .item-card-absolute {
        width: 100%;
        height: 100%;
    }
    .item .item-card .item-card-wrap-image {
        width: 100%;
        height: 190px;
        object-fit: cover;
    }
    .item .item-card .item-card-wrapper-bottom-button {
        width: 100%;
    }
    .no-photo {
        height: 390px;
    }
}

@media(max-width: 980px) {
   .item .item-card-wrapper-name {
       font-size: calc(10px + 1vw);
       line-height: 1.3;
   }
    .item .item-card-wrapper-bottom-price {
        font-size: calc(12px + 1vw);
        line-height: 1.3;
    }
    .item .item-card .item-card-wrapper-bottom-button {
        font-size: calc(10px + 1vw);
    }
    .item .item-card .btns .btns-remove,
    .item .item-card .btns .btns-count,
    .item .item-card .btns .btns-add, {
        font-size: calc(10px + 1vw);
    }
}

@media(max-width: 880px) {
    .item .item-card {
        max-width: 270px;
    }
}

@media(max-width: 680px) {
    .item .item-card {
        max-width: 220px;
    }
}

@media(max-width: 580px) {
    .item .item-card {
        max-width: 210px;
    }
    .item .item-card-wrapper {
        min-height: 160px;
    }
    .item .item-card-wrapper-name {
        min-height: 30px;
    }
    .item .item-card .item-card-wrap-image {
        height: 130px;
    }
    .item .item-card .item-card-wrapper-bottom-button {
        height: 40px;
    }
    .item .item-card .btns .btns-remove,
    .item .item-card .btns .btns-count,
    .item .item-card .btns .btns-add, {
        height: 40px;
    }
    .no-photo {
        height: 298px;
    }
}

@media(max-width: 440px) {
    .item .item-card {
        max-width: 140px;
    }
    .item .item-card-wrapper {
        min-height: 100px;
    }
    .item .item-card-wrap-svg {
        width: 24px;
        height: 24px;
        top: 10px;
        right: 10px;
    }
    .item .item-card-wrapper-bottom {
        height: 80px;
    }
    .item .item-card .item-card-wrapper-bottom-button {
        height: 30px;
    }
    .item .item-card .btns .btns-remove,
    .item .item-card .btns .btns-count,
    .item .item-card .btns .btns-add, {
        height: 30px;
    }
    .no-photo {
        height: 268px;
    }
}
