.orderForm {
    margin-top: 90px;

    &-wrapper {

        margin-bottom: 70px;

        &-title {
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 45px;
            color: #1D194B;
            margin-bottom: 30px;
        }

        &-div {
            display: flex;

            &-item {
                display: flex;
                flex-direction: column;
                margin-right: 25px;

                &-label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 30px;
                    color: #08003C;
                }

                &-input {
                    width: 386px;
                    height: 62px;
                    left: 120px;
                    top: 1083px;
                    border: 1px solid #9C99AD;
                    border-radius: 10px;
                    padding: 16px 21px;
                }
            }

            &-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 278px;
                height: 74px;
                border: none;
                border-radius: 50px;
                color: rgba(63, 102, 176, 0.47);
                background: rgba(216, 232, 255, 0.83);
                cursor: pointer;
                &:first-child{
                    margin-right: 15px;
                }
            }
        }

        &-button {
            width: 456px;
            height: 74px;
            border: none;
            border-radius: 50px;
            background: #3F66B0;
            color: #fff;
            margin-right: 20px;
            cursor: pointer;
        }

        &-select {
            width: 387px;
            height: 62px;
            border: 1px solid #9C99AD;
            border-radius: 10px;
            margin-right: 20px;
            padding: 16px 22px;
            color: rgba(8, 0, 60, 0.2);
        }

    }

}

.activeTabs {
    background: #3F66B0;
    color: #fff;
}

@media(max-width: 1366px) {
    .orderForm .orderForm-wrapper-div {
        flex-wrap: wrap;
    }
    .orderForm .orderForm-wrapper-div .orderForm-wrapper-div-item {
        margin-top: 15px;
    }
}

@media(max-width: 980px) {
    .orderForm .orderForm-wrapper {
        margin-bottom: 2rem;
    }
    .orderForm .orderForm-wrapper-title {
        font-size: calc(28px + 1vw);
        margin-bottom: 0.1rem;
    }
    .orderForm .orderForm-wrapper-div .orderForm-wrapper-div-item input{
        width: 30vw;
        height: 2.5rem;
    }
    .orderForm .orderForm-wrapper-div .orderForm-wrapper-div-item label{
        font-size: calc(14px + 1vw);
    }
    .orderForm .orderForm-wrapper-div .orderForm-wrapper-div-button {
        padding: 0;
        width: 40vw;
        min-width: 115px;
        height: 3rem;
        margin-top: 15px;
    }
    .orderForm .orderForm-wrapper .orderForm-wrapper-button {
        height: 4rem;
        width: 50vw;
    }
    .orderForm .orderForm-wrapper .orderForm-wrapper-select {
        width: 30vw;
        height: 2.5rem;
        padding: 0 22px;
    }
}

@media(max-width: 768px) {
    .orderForm .orderForm-wrapper-div .orderForm-wrapper-div-item input{
        width: 40vw;
    }
}

@media(max-width: 700px) {
    .orderForm .orderForm-wrapper-div .orderForm-wrapper-div-item {
        width: 100%;
    }
    .orderForm .orderForm-wrapper-div .orderForm-wrapper-div-item input{
        width: 100%;
    }
    .orderForm .orderForm-wrapper-div .orderForm-wrapper-div-button {
        width: 45%;
    }
    .orderForm .orderForm-wrapper .orderForm-wrapper-button {
        width: 100%;
    }
    .orderForm .orderForm-wrapper .orderForm-wrapper-select {
        width: 45%;
        min-width: 125px;
    }
    .orderForm .orderForm-wrapper-select:last-child{
        margin-right: 0;
    }
}

@media(max-width: 480px) {
    .orderForm .orderForm-wrapper-div .orderForm-wrapper-div-item {
        width: 100%;
    }
    .orderForm .orderForm-wrapper-div .orderForm-wrapper-div-item input{
        width: 100%;
    }
}
