.footer-basket {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    height: 83px;
    width: 100%;
    padding: 20px 130px;
    background-color: #3F66B0;
    z-index: 12;
    align-items: center;

    &-wrap {
        display: flex;
        align-items: center;
    }

    &-count {
        text-decoration-line: underline;
        color: #FFFFFF;
        margin-right: 40px;
        cursor: pointer;
    }

    &-sum {
        font-weight: 600;
        line-height: 45px;
        margin-right: 100px;
        color: #FFFFFF;
    }

    &-button {
        font-weight: 500;
        line-height: 40px;
        color: #08003C;
        flex: none;
        order: 0;
        flex-grow: 0;
        padding: 5px 100px;
        cursor: pointer;
        background: #B0CBFF;
        border-radius: 50px;
        border: transparent;
    }
}

@media(max-width: 980px) {
    .footer-basket {
        padding: 1rem 2rem;
    }
}

@media(max-width: 980px) {
    .footer-basket {
        padding: 1rem 2rem;
    }
    .footer-basket .footer-basket-button {
        padding: 5px 3rem;
        line-height: 1.4rem;
    }
}

@media(max-width: 560px) {
    .footer-basket {
        padding: 1rem 1rem;
        justify-content: space-between;
    }
    .footer-basket .footer-basket-count {
        margin-right: 20px;
    }
    .footer-basket .footer-basket-sum {
        margin-right: 20px;
    }
}

@media(max-width: 420px) {
    .footer-basket .footer-basket-count {
        margin-right: 15px;
        font-size: 16px;
    }
    .footer-basket .footer-basket-sum {
        margin-right: 20px;
        font-size: 16px;
    }
    .footer-basket .footer-basket-button {
        padding: 5px 2rem;
        font-size: 16px;
    }
}