.mySection {
    &-wrapper {
        div {
            .item:last-child{
                margin-right: 0;
            }
        }
        &-time {
            margin-bottom: 25px;
            font-size: 25px;
            font-weight: 500;
        }
    }
}