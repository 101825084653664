.checkbox {
    display: flex;
    align-items: center;
    width: 33%;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    padding-left: 0;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding-left: 0 !important;
}

.css-dmmspl-MuiFormGroup-root {
    align-items: center;
}

@media(max-width: 980px) {
    .modal-wrapper .css-1oqfbwd-MuiSwitch-root .MuiSwitch-thumb {
        width: 18px;
        height: 18px;
    }
    .modal-wrapper .css-1oqfbwd-MuiSwitch-root {
        height: 22px;
    }
    .modal-wrapper .checkbox {
        width: 45%;
    }
    .modal-wrapper .checkbox p {
        font-size: calc(12px + 1vw);
    }
    .modal-wrapper .css-i4bv87-MuiSvgIcon-root {
        width: 1.5rem;
        height: 1.5rem;
    }
}