.order {
    padding: 0px 130px;

    &-wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 23px;
        border-bottom: 4px solid rgba(216, 232, 255, 0.38);


        &-title {
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 45px;
            color: #1D194B;
        }

        &-delete {
            display: flex;
            justify-content: center;
            align-items: center;

            &-title {
                cursor: pointer;
                margin-right: 15px;
            }

            &-icon {
                cursor: pointer;
            }
        }
    }

    &-total {

        display: flex;
        justify-content: flex-end;
        align-items: center;

        &-price {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
            font-weight: 400;
            font-size: 34px;
            line-height: 1.1;
            color: #08003C;
        }
    }

}

@media(max-width: 1366px) {
    .order {
        padding: 1rem 3rem;
    }
}

@media(max-width: 980px) {
    .order {
        padding: 1rem 2rem;
    }
    .order .order-wrapper-title {
        font-size: calc(16px + 2vw);
    }
    .order .orders-all-item-div-options-title {
        font-size: calc(12px + 1vw);
    }
    .order .orders-all-item-div-div-price {
        font-size: calc(14px + 1vw);
    }
    .order .orders-all-item-div-div-weight {
        font-size: calc(12px + 1vw);
    }
    .order .order-total-price {
        font-size: calc(16px + 1vw);
    }

}

@media(max-width: 560px){
    .order {
        padding: 1rem 1rem;
    }
}