.orderModal {
    position: absolute;
    bottom: 100px;
    right: 400px;
    padding: 26px 26px;
    background: #FFFFFF;
    box-shadow: 0px -2px 18px rgba(29, 25, 75, 0.12);
    border-radius: 20px;
    z-index: 11;
    max-height: 450px;
    overflow-y: scroll;

    &-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &-item-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 24px;
        padding-bottom: 24px;

        &-name {
            width: 250px;
        }

        &-price {
            width: 50px;
        }
    }

    &-button {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: #3F66B0;
        border-radius: 50px;
        color: #FFFFFF;
        padding: 20px 0px;
        border: none; 
    }

    &-item-wrapper:not(:last-child) {
        border-bottom: 1px solid rgba(176, 203, 255, 0.5);
    }
}