.modal-wrapper {
    max-width: 1000px;

    &-img {
        max-height: 365px;
        width: 100%;
    }

    &-content {
        padding: 2rem 3rem;
        max-width: 900px;

        &-title {
            font-style: normal;
            font-weight: 500;
            font-size: 34px;
            line-height: 36px;
            color: #08003C;
            margin-bottom: 30px;
        }

        &-description {
            margin-bottom: 20px
        }

        &-p {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            color: #08003C;
            margin-bottom: 20px;
        }

        &-options {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 70px;
        }

        &-ingedients {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 70px;
        }

        &-btns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 55px;

            &-buttons {

                width: 50%;
                display: flex;
                &-order {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 50px;
                    border: none;
                    border-radius: 20px;
                    background: #ee3a3a;
                    color: #fff;
                    margin-right: 15px;
                    cursor: pointer;
                }

                &-button {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    gap: 8px;
                    z-index: 110;
                    height: 50px;
                    left: 16px;
                    bottom: 14px;
                    background-color: transparent;
                    border: 1px solid #1D194B;
                    border-radius: 20px;
                    margin-right: 20px;
                }
            }

        }
    }
}

.ReactModal__Overlay {
    z-index: 115;
}

.css-dmmspl-MuiFormGroup-root {
    width: 33.3%;
    flex-wrap: row nowrap !important;
    display: flex;
    -webkit-flex-wrap: nowrap !important;
}

.css-1h7anqn {
    width: 33.3%;
    display: flex;
    flex-direction: row;
    flex-wrap: row nowrap !important;
    align-items: center;
    flex-flow: row nowrap !important;
}


.modal-close {
    position: absolute;
    z-index: 10;
    top: 2%;
    right: 2%;
    color: #000;
    cursor: pointer;
    width: 60px;
    height: 60px;
    padding: 10px;
}

.ReactModal__Overlay {
    z-index: 10;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.75);
}

.ReactModal__Content {
    position: absolute;
    left: 2.5rem;
    right: 2.5rem;
    top: 2.5rem;
    bottom: 2.5rem;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,97,0.5);
    overflow: auto;
    outline: none;
    height: 100%;
    border-radius: 20px;

    // inset: 86% auto auto 50% !important;
    /* полоса прокрутки (скроллбар) */
    &::-webkit-scrollbar {
        width: 14px; /* ширина для вертикального скролла */
        height: 8px; /* высота для горизонтального скролла */
        background-color: transparent;
    }
    /* ползунок скроллбара */
    &::-webkit-scrollbar-thumb {
        background: rgba(11, 19, 90, 0.08);
        border-radius: 20px;
    }
    /* Стрелки */

    &::-webkit-scrollbar-button:vertical:start:decrement {
        display: none;
    }

    &::-webkit-scrollbar-button:vertical:end:increment {
        display: none;
    }
}

@media(max-width: 980px) {
    .modal-wrapper .modal-wrapper-content {
        padding: 2rem 2rem;
    }
    .modal-wrapper .modal-wrapper-content-ingedients {
        justify-content: space-between;
    }
    .modal-wrapper .modal-wrapper-content-title,
    .modal-wrapper .modal-wrapper-content-options {
        font-size: calc(16px + 1vw);
    }
    .modal-wrapper .modal-wrapper-content-description,
    .modal-wrapper .modal-wrapper-content-ingedients {
        font-size: calc(10px + 1vw);
    }
    .modal-wrapper .modal-wrapper-content-p,
    .modal-wrapper h2 {
        font-size: calc(12px + 1vw);
    }
    .modal-wrapper .css-dmmspl-MuiFormGroup-root {
        width: 50%;
    }
    .modal-wrapper .modal-wrapper-content-btns-buttons-button,
    .modal-wrapper .modal-wrapper-content-btns-buttons-order {
        height: 2.3rem;
        min-width: 110px;
    }
}

@media(max-width: 400px){
    .modal-wrapper .modal-wrapper-content-btns {
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    .modal-wrapper .modal-wrapper-content-btns-buttons {
        width: 100%;
        margin-top: 20px;
    }
}
