.team {
    padding: 180px 25px;

    &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 74px;
        line-height: 83px;
        color: #1D194B;
        margin: 0 auto 60px;
        display: block;
        width: fit-content;
    }

    &-persons {
        display: flex;
        justify-content: center;
    }

    .team-persons {
        display: flex;
        flex-direction: column;
    }

}

@media(max-width: 980px) {
    .team .team-title {
       font-size: calc(28px + 2vw);
    }
    .team .person-name {
        font-size: calc(22px + 2vw);
    }
}

@media(max-width: 840px) {
    .team {
        padding: 0 1rem 5rem;
    }
    .team .team-persons {
        flex-direction: column;
    }
}