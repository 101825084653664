.footer {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 19px 123px;
	height: 409px;
    background-image: url('../../assets/imgs/footer-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    align-items: flex-end;
    position: relative;
    left: 0;
    bottom: 0;
    z-index: 9;
    .footer-info{
        min-width: 400px;
        gap: 180px;
        display: flex;
        flex-direction: row;
        align-items: end;
        .footer-item{
            width: 190px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .footer-hours{
                display: flex;

        flex-direction: column;

        li {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

          div {
            font-size: 18px;
          }
        ;
        }
      }

      .title {
        margin-bottom: 10px;
      }

      .discr {
        color: #1D194B;
        font-size: 18px;

      }
    }

  }

  &-nav a:not(:last-child) {
    margin-right: 30px;
  }
}

@media(max-width: 980px) {
  .footer {
    padding: 0 2rem 2rem;
    height: 280px;
  }
}

@media(max-width: 768px) {
  .footer {
    margin-top: 160px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 185px;
  }
  .footer .footer-nav {
    margin-top: 25px;
  }
  .footer .footer-nav a {
    font-size: calc(10px + 1.5vw);
  }
}

