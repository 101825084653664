.menu {
    .submenu{
        display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
        img{
             width: 100%; /* Ширина изображения 100% от ширины контейнера */
    height: auto;
        }
    }
    &-menu {

        display: flex;
        justify-content: center;
        padding: 40px 130px;

        &-wrapper {

            &-image {
                background-image: url('../../assets/imgs/menu-image.png');
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 228px;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-weight: 500;
                font-size: 52px;
                line-height: 59px;
                margin-bottom: 40px;
            }
        }
    }
}

@media(max-width: 1480px){
    .menu-menu {
        padding: 2rem 4rem;
    }
}
@media(max-width: 1366px){
    .menu-menu {
        padding: 2rem 3rem;
    }
}
@media(max-width: 980px){
    .menu-menu {
        flex-direction: column;
        padding: 2rem 2rem;
    }
    .menu-menu .menu-menu-wrapper-image {
        font-size: calc(20px + 2vw);
    }
}

@media(max-width: 650px){
    .menu-menu .menu-menu-wrapper-image {
        height: 8rem;
    }
}