.section {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

@media(max-width: 1480px) {
    .section {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
        width: 900px;
    }
}

@media(max-width: 1366px) {
    .section {
        grid-template-columns: repeat( auto-fit, minmax(240px, 1fr) );
        width: 900px;
    }
}

@media(max-width: 1280px) {
    .section {
        grid-template-columns: repeat( auto-fit, minmax(240px, 1fr) );
        width: 800px;
    }
}

@media(max-width: 1170px) {
    .section {
        grid-template-columns: repeat( auto-fit, minmax(240px, 1fr) );
        width: 620px;
    }
}

@media(max-width: 1150px) {
    .section {
        width: 620px;
    }
}

@media(max-width: 880px) {
    .section {
        width: 600px;
    }
}

@media(max-width: 680px) {
    .section {
        width: 500px;
    }
}

@media(max-width: 580px) {
    .section {
        width: 400px;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}

@media(max-width: 440px) {
    .section {
        width: 300px;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}