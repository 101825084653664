.about {
    display: flex;
    padding: 157px 0px 0px 120px;
    position: relative;
    justify-content: center;

    &-wrapper {
        max-width: 660px;

        &-wrap {
            display: flex;
            align-items: center;
            margin-bottom: 31px;

            &-div {
                width: 209px;
                height: 3px;
                background-color: #ACC9FF;
                margin-left: 12px;

            }
        }

        &-title {
            font-size: 74px;
            font-weight: 600;
            margin-bottom: 12px;
            margin-left: 80px;

        }

        &-discr {
            font-size: 26px;
            line-height: 42px;
            padding-right: 100px;
        }
    }

    &-image {
        background-image: url(../../assets/imgs/about_us_4.jpg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 710px;
        height: 660px;
        position: relative;
    }

    &-stats {
        width: 692px;
        height: 178px;
        border-left: 10px solid #B0CCFF;
        border-radius:  10px;
        background-color: #fff;
        box-shadow: 0px 2px 16px 14px rgba(176, 204, 255, 0.16);
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        z-index: 20;
        bottom: -40px;

        &-wrapper {
            h3 {
                font-size: 46px;
                font-weight: 500;
                color: #08003C;
            }

            p {

            }
        }
    }

}

@media(max-width: 980px) {
    .about {
        flex-direction: column;
        padding: 5rem 2rem;
    }
    .about .about-wrapper {
        order: 1;
        margin-bottom: 30px;
    }
    .about .about-wrapper .about-wrapper-title {
        font-size: calc(28px + 2vw);
    }
    .about .about-wrapper .about-wrapper-discr {
        font-size: calc(12px + 2vw);
    }
    .about .about-image {
        order: 3;
        width: unset;
        height: calc(200px + 30vw);
        background-size: cover;
        border-radius: 12px;
    }
    .about .about-stats {
        position: relative;
        order: 2;
        bottom: unset;
        margin-bottom: 30px;
        width: unset;
        border: none;
    }
    .about .about-stats h3 {
        font-size: calc(14px + 2vw);
    }
    .about .about-stats p {
        font-size: calc(12px + 2vw);
    }
}

@media(max-width: 768px) {
    .about .about-stats {
        padding: 25px 30px;
        height: unset;
    }
    .about .about-stats .wrapper:nth-child(2) {
        margin: 0 15px;
    }
    .about .about-stats h3 {
        font-size: calc(14px + 2vw);
    }
    .about .about-stats p {
        font-size: calc(12px + 1.5vw);
    }
    .about .about-stats .wrapper {
        flex: 1 1 auto;
    }
}

@media(max-width: 560px) {
    .about {
        padding: 5rem 1rem;
    }
    .about .about-stats {
        padding: 20px;
    }
    .about .about-stats h3 {
        font-size: calc(18px + 1vw);
    }
    .about .about-stats p {
        font-size: calc(12px + 1vw);
    }
}

@media(max-width: 440px) {
    .about .about-stats {
        flex-direction: column;
        align-items: flex-start;
    }
    .about .about-stats .wrapper:nth-child(2) {
        margin: 15px 0;
    }
    .about .about-stats h3 {
        font-size: calc(18px + 3vw);
    }
    .about .about-stats p {
        font-size: calc(12px + 2vw);
    }
}

