body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, 
form, fieldset, input, p, blockquote, table, th, td, embed, object {
	padding: 0;
	margin: 0; 
	}
table {
	border-collapse: collapse;
	border-spacing: 0;
	}
fieldset, img, abbr {
	border: 0;
	}
address, caption, cite, code, dfn, em, 
h1, h2, h3, h4, h5, h6, strong, th, var {
	font-weight: normal;
	font-style: normal;
	}
ul {
	list-style: none;
	}
caption, th {
	text-align: left;
	}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Nobile', sans-serif;
	font-size: 1.0em;
	}
q:before, q:after {
	content: '';
	}
a, ins {
	text-decoration: none;
	}

p, li, table, th, td, a, div {
    font-family: 'Poppins', sans-serif;
	color: #1D194B;
}

.outlet {
	min-height: calc(100vh - 577px);
}

* {
    font-size: 22px;
	font-weight: 400;
	box-sizing: border-box;
}
html {
	overflow-x: hidden;
}
body {
	overflow-x: hidden;
}
@media(max-width: 768px){
	* {
		font-size: 18px;
	}
}

@media(max-width: 375px){
	* {
		font-size: 14px;
	}
}